import type { CarouselItem } from '@/entities/ui/carousel/carousel-item';

export const defaultItems: CarouselItem[] = [
  {
    Name: 'EXHUMA: QUẬT MỘ TRÙNG MA',
    OriginalName: 'Exhuma',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/exhuma-quat-mo-trung-ma/exhuma-quat-mo-trung-ma-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
];
