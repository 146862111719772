<template>
  <div class="m-carousel p-3">
    <u-carousel v-bind="carouselConfig">
      <template v-slot="{ item }">
        <slot :item="item">
          <nuxt-link :to="item?.URL" class="br-1 mx-2">
            <div
              class="item-wrapper flex relative items-end"
              :style="{ backgroundImage: `url(${item?.Thumbnail})` }"
            >
              <div class="item-names">
                <div class="font-bold">
                  {{ item?.Name }}
                </div>
                <div class="text-xs">
                  {{ item?.OriginalName }}
                </div>
              </div>
            </div>
            <span class="ribbon">{{ item?.Quality }}</span>
          </nuxt-link>
        </slot>
      </template>
    </u-carousel>
  </div>
</template>
<script setup lang="ts">
import type { CarouselItem } from '@/entities/ui/carousel/carousel-item';
import { defaultItems } from './default-data';

const props = withDefaults(
  defineProps<{
    items: CarouselItem[];
    ui?: any;
  }>(),
  {
    items: defaultItems as any,
  }
);

const carouselConfig = computed(() => ({
  items: props.items,
  arrows: true,
  // ui: {
  // },
  ui: props.ui,
  prevButton: {
    class: 'arrow-button left',
    color: 'white',
  },
  nextButton: {
    class: 'arrow-button right',
    color: 'white',
  },
}));
</script>

<style scoped lang="scss">
.item-wrapper {
  height: 300px;
  width: 225px;
  background-repeat: no-repeat;
  background-size: cover;
}

.item-names {
  background: rgba(0, 0, 0, 0.8);
  padding: 4px;
  width: 100%;
  margin-bottom: 2px;
}

.m-carousel {
  background-color: var(--app-item-background-odd);
}
</style>

<style lang="scss">
.m-carousel {
  .arrow-button {
    padding: 30px 10px;
    background: rgba(0, 0, 0, 0.6) !important;
    border-radius: 0;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
}
</style>
